<template>
  <div class="my-8">
    <div class="flex justify-between">
      <h1 class="text-xl font-bold">Fees</h1>
    </div>

    <div class="flex flex-col mt-4">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="
              shadow
              overflow-hidden
              border-b border-gray-200
              sm:rounded-lg
            "
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Range
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Fee
                  </th>

                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Agent Share
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Last Updated
                  </th>

                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Print</span>
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="(fee, i) in data.fees" :key="i">
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm font-medium text-gray-900">
                      GMD
                      <b>{{ fee.from }}</b>

                      -
                      <b>{{ fee.to }}</b>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm font-medium text-gray-900">
                      GMD
                      <input
                        class="w-20 p-2"
                        :class="
                          editOn == i && 'border border-green-400 rounded'
                        "
                        v-model="fee.fee"
                        :disabled="editOn != i"
                        type="number"
                      />
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      <input
                        class="w-20 p-2"
                        :class="
                          editOn == i && 'border border-green-400 rounded'
                        "
                        v-model="fee.agent_share"
                        :disabled="editOn != i"
                        type="number"
                      />
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      {{ fee.updated_at | moment("Do MMM, YYYY, h:mm:ss a") }}
                    </div>
                  </td>

                  <td class="px-6 py-4 text-sm text-right">
                    <button
                      @click="onSave(fee)"
                      v-if="isEditing && editOn === i"
                      class="text-green-800 font-bold"
                    >
                      Save
                    </button>
                    <button v-else @click="onEditFee(i)">Edit</button>
                  </td>
                </tr>

                <!-- More people... -->
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import moment from "moment";
import FormatPrice from "@/mixins/FormatPrice";

export default {
  props: ["data"],

  mixins: [FormatPrice],

  data: () => ({
    isEditing: false,
    editOn: null,
  }),

  created() {},

  methods: {
    onEditFee(i) {
      this.isEditing = true;
      this.editOn = i;
    },

    async onSave(fee) {
      if (fee.agent_share > 100) {
        return false;
      }

      try {
        const { data } = await axios.patch("/update_fees", fee);

        console.log("data", data);
        this.isEditing = false;
        this.editOn = null;
        this.$emit("feeUpdated");
      } catch (e) {
        console.log("Error", e);
      }
    },
  },
};
</script>
