<template>
  <div class="">
    <div class="flex">
      <div class="container mx-auto">
        <div class="flex">
          <div class="w-1/4 pt-6">
            <div class="mb-4 border-b pb-5">
              <div class="text-3xl font-medium text-grey-darkest">
                {{ agent.entity_name }}
              </div>
              <div class="text-xl text-grey-dark font-light">
                {{ agent.contact_person }}
              </div>
              <div class="mt-3 text-sm text-grey-dark font-light">
                <span class="font-medium text-grey-darkest">Address: </span
                >{{ agent.business_address }}
              </div>
              <div class="mt-3 text-sm text-grey-dark font-light">
                <span class="font-medium text-grey-darkest">Phone: </span
                >{{ agent.contact_person_number }}
              </div>
              <div class="mt-3 text-sm text-grey-dark font-light">
                <span class="font-medium text-grey-darkest"
                  >Nature of Business: </span
                >{{ agent.nature_of_business }}
              </div>
            </div>
          </div>
          <div class="w-3/4 ml-6 mt-2">
            <div
              class="
                flex
                items-center
                font-thin
                text-grey-dark text-sm
                border-b
              "
            >
              <button
                @click="current = 'overview'"
                :class="{ 'border-blue-500 border-b-2': current == 'overview' }"
                class="p-4 font-normal text-grey-darkest"
              >
                Overview
              </button>

              <button
                @click="current = 'fees'"
                :class="{
                  'border-blue-500 border-b-2': current == 'fees',
                }"
                class="
                  p-4
                  flex
                  items-center
                  font-normal
                  hover:text-blue-800 hover:border-b-2 hover:border-blue-500
                "
              >
                <div>Fees</div>
              </button>

              <button
                @click="current = 'settings'"
                :class="{
                  'border-blue-500 border-b-2': current == 'settings',
                }"
                class="
                  p-4
                  flex
                  items-center
                  font-normal
                  hover:text-blue-800 hover:border-b-2 hover:border-blue-500
                "
              >
                <div>Settings</div>
              </button>
            </div>
            <div class="flex m items-center"></div>
            <overview
              v-if="current === 'overview'"
              :data="agent"
              @complete="onComplete"
            />

            <fees
              v-if="current === 'fees'"
              :data="agent"
              @feeUpdated="loadData($route.params.id)"
            />
            <settings v-if="current === 'settings'" :data="agent" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Overview from "./View/Overview";
import { mapGetters } from "vuex";
import Settings from "./View/Settings.vue";
import Fees from "./View/Fees.vue";
import checkPermissionExist from "../../mixins/checkPermissionExist";

export default {
  middleware: "auth",

  components: { Fees, Overview, Settings },
  layout: "default",

  mixins: [checkPermissionExist],
  computed: mapGetters({
    user: "auth/user",
  }),

  data: () => ({
    isLoading: false,
    agent: {
      // contact_person_number: "",
      // accounts: [],
      // balance: "",
      // entity_name: "",
      // id: "",
      // status: "",
    },

    current: "overview",
  }),

  created() {
    var _this = this;
    _this.loadData(this.$route.params.id);

    // if (!this.checkPermissionExist(this.user.data, "staffs-read")) {
    //   this.$router.go(-1);
    //   return false;
    // }
  },

  mounted() {
    // this.agent = this.$route.query;
    // console.log("Paramssss", this.agent);
  },

  methods: {
    onComplete() {
      console.log("Loading the agent");
      // this.loadData(this.$route.params.id);
    },

    async loadData(id) {
      //Get Data

      this.isLoading = true;
      try {
        const { data } = await axios.get(`/superagent/${id}`);
        console.log("AGENTTTTTTTTTTTTTTTTTT", data);
        this.isLoading = false;
        this.agent = data.data;
        console.log("Agentttt", this.agent.actualbalance);

        return true;
      } catch (e) {
        console.log("Error");
        this.isLoading = false;
        return false;
      }
    },
  },
};
</script>
